body {
  color: #121240;
}

input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='date'],
input[type='tel'],
input[type='url'],
input[type='password'],
input[type='range'],
textarea,
.react-tel-input .form-control,
select {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #bcbcc9;
  font-weight: 500;
  font-size: 0.9em;
  border-radius: 0;
}

.flex-content-center {
  display: flex;
  justify-content: center;
}


a.description-link{
  color: #0c0c6d;
  text-decoration: none;
  font-size: 1.1em;
}

.cost-calculator label, .input-label {
  position: relative;
}

.input-tooltip {
  left: 95%;
  top: 7px;
  position: absolute;
  display: block;
  width: 14px;
  height: 12px;
  background: transparent url('./img/info-icon.svg') no-repeat center center;
  background-size: contain;
}
.input-tooltip:hover .tooltip-text {
  visibility: visible;
}
.input-tooltip .tooltip-text {
  visibility: hidden;
  width: auto;
  background-color: #494258;
  color: #ffffff;
  text-align: left;
  padding: 5px 10px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 300px;
  width: 200px;
}

.range-indicator {
  display: inline-block;
  margin-left: 10px;
  background-color: #00005a;
  border-radius: 25px;
  padding: 4px 6px;
  color: #ffffff;
  min-width: 16px;
  text-align: center;
  font-size: 0.8em;
  -webkit-appearance: none;
  outline: none;
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #bcbcc9;
  outline: none;
  margin-top: 20px;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 3px solid #00005a;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 2px;
}

.range-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 3px solid #00005a;
  cursor: pointer;
  border-radius: 25px;
}

input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='date']:focus,
input[type='text']:focus,
input[type='tel']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='range']:focus,
textarea:focus,
select:focus {
  border: none;
  border-bottom: 2px solid #00005a;
  box-shadow: none;
}

.button.button-primary,
button.button-primary,
input[type='submit'].button-primary,
input[type='reset'].button-primary,
input[type='button'].button-primary {
  padding: 0 60px;
  font-size: 0.9em;
  color: #ffffff;
  background-color: #00005a;
  border-color: #00005a;
  border-radius: 25px;
  height: 40px;
  margin: 20px auto;
  float: right;
}

.button.button-primary:hover,
button.button-primary:hover,
input[type='submit'].button-primary:hover,
input[type='reset'].button-primary:hover,
input[type='button'].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type='submit'].button-primary:focus,
input[type='reset'].button-primary:focus,
input[type='button'].button-primary:focus {
  color: #28faa9;
  background-color: #00005a;
  border-color: #00005a;
}

.button-primary.mini-button {
  height: 25px;
  line-height: 20px;
  padding: 5px 10px;
  font-size: 0.4em;
  color: #ffffff;
  float: right;
  margin: 0 auto;
  border-color: transparent;
  width: 50px;
}
.button-primary.mini-button:hover,
.button-primary.mini-button:focus {
  background-color: #0c0c6d;
  border-color: transparent;
}

.button-primary.export-button {
  padding: 0 20px;
}

.close-button {
  border: none;
  background: transparent url('./img/close-icon.svg') no-repeat center center;
  float: right;
  margin-right: 20px;
}

.header .mini-button {
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 8px 8px;
  margin-right: 15px;
  height: 35px;
  width: auto;
}

.switch-warp {
  background: #fafafa;
  padding: 8px 20px;
  margin: 10px auto;
  border-radius: 8px;
  border: 2px solid #f4f3f7;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}
.switch-label {
  display: inline-block;
  font-size: 1.1em;
  min-width: 220px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 11px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -1px;
  bottom: -1px;
  background-color: #ffffff;
  border: 2px solid #494258;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.07);
}

input:checked + .slider {
  background-color: #00005a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00005a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background-color: #28faa9;
  border: 2px solid #00005a;
}

label {
  color: #494258;
  font-weight: 500;
}

.cs-select {
  position: relative;
  margin-bottom: 1.5em;
  border-bottom: 1px solid #bcbcc9;
  box-shadow: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: url(./img/arrow-down.svg) no-repeat 95% center transparent;
  color: #1c1c57;
}

.cost-saver-warp {
  background: #f9fafb;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin-top: 5%;
  height: auto;
}
.header {
  background: #00005a;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  padding: 20px 0;
}
.logo {
  width: 120px;
  margin-left: 20px;
  margin-top: 8px;
}
.initial-data {
  float: right;
  margin: 8px auto;
}

.head-data-block {
  display: inline-block;
  margin-right: 30px;
}

.main {
  padding: 20px 20px;
}
.content,
.initial-form,
.forms-warp,
.introduction-warp,
.export-box-warp {
  background: #ffffff;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  margin-top: 5%;
  height: auto;
  padding-top: 20px;
}

.main-title {
  text-align: center;
  font-size: 26px;
  font-weight: 400;
}

.export-box-warp {
  padding: 20px;
}
.introduction-warp .section-title {
  text-align: left;
  margin-left: 4%;
}

.description-box-warp {
  padding: 0px 20px;
  background: #f9fafb;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #e3e2e9;
}
.description-box-warp .description {
  margin-top: 20px;
}

.step-box {
  background: #ffffff;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  min-height: 80px;
  cursor: pointer;
}
.step-box-title {
  font-size: 1.1em;
  font-weight: 400;
  text-align: center;
}
.step-box-icon {
  height: 50px;
  width: 50px;
  margin: 10px auto;
  filter: grayscale(100%);
  background-size: contain !important;
}
.step-box.isActive .step-box-icon {
  filter: grayscale(0%);
}
.step-box.isActive .step-box-title {
  font-weight: 500;
}

.section-icon {
  height: 90px;
  width: 90px;
  margin: 10px auto;
}
.section-title {
  font-size: 1.8em;
  text-align: center;
}

.assign-default-data,
.opt-setting {
  margin: 20px auto 40px auto;
}

.assign-default-data h5,
.opt-setting h5,
.button-section-title {
  font-size: 1em;
  font-weight: 500;
  display: inline;
}

.claim-case-icon {
  background: transparent url('img/claim-case-icon.svg') no-repeat top center;
}
.claim-icon {
  background: transparent url('img/claim-icon.svg') no-repeat top center;
}
.sales-icon {
  background: transparent url('img/sales-icon.svg') no-repeat top center;
}
.debt-collection-icon {
  background: transparent url('img/debt-collection-icon.svg') no-repeat top center;
}
.calculator-icon {
  background: transparent url('img/calculator-icon.svg') no-repeat top center;
}
.intro-icon {
  height: 132px;
  width: 132px;
  background: transparent url('img/cost-saver-icon.svg') no-repeat top center;
}

.blocks-title {
  font-size: 0.9em;
  font-weight: 500;
  color: #a3a1b4;
  text-align: center;
  margin-bottom: 0.5em;
}

.number-blocks {
  margin-top: 20px;
}
.number-block {
  background: #ffffff;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  margin-bottom: 15px;
  opacity: 0.5;
}
.number-block.active-block {
  opacity: 1;
}

.number-block-amount {
  background: #00005a;
  color: #ffffff;
  border-radius: 0 8px 8px 0;
  padding: 4px;
}
.number-block-save {
  background: #28faa9;
  color: #00005a;
  border-radius: 0 8px 8px 0;
  padding: 4px;
  text-align: center;
}

.number-block-save span {
  font-size: 1.05em;
  font-weight: 600;
}

.number-block-title {
  background: #ffffff;
  padding: 4px;
  border-radius: 8px 0 0 8px;
}
.number-block-title .title {
  margin-left: 5px;
  font-size: 1em;
}
.number-block-title .save-title {
  font-size: 1.05em;
  font-weight: 600;
  margin-left: 5px;
}
.number-block-amount span {
  margin-left: 10px;
  width: 120px;
  display: inline-block;
}
.number-block-amount span.notVisible {
  visibility: hidden;
}

.number-block-amount span::before {
  content: '';
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 7px;
  background-color: #ffffff;
  margin-right: 5px;
  vertical-align: middle;
}

.CAI-amount {
  color: #28faa9;
}

span.CAI-amount::before {
  background-color: #28faa9;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-content {
  position: fixed;
  background: white;
  width: 990px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 0;
  border-radius: 25px;
}
.button-modal {
  margin-right: 50px !important;
  position: relative;
}
.modal-description {
  margin-bottom: 40px;
  text-align: left;
}

.optimizer-modal {
  position: relative;
  background: white;
  width: 970px;
  height: 500px;
  padding: 20px 0;
  overflow-y: scroll;
  margin: 0 auto;
}

.optimizer-modal hr {
  margin: 0.5em auto;
}

.inputs-group {
  margin-top: 20px;
  padding-bottom: 10px;
}
.optimizer-modal h5 {
  font-size: 1.3em;
  text-align: left;
  line-height: 0.2em;
  font-weight: 400;
}

.optimizer-modal label {
  float: left;
  width: 250px;
  padding-top: 15px;
}
.optimizer-input {
  float: right;
}
.optimizer-range {
  margin-top: 20px;
}
.post-input-des {
  font-size: 0.7em;
  margin-top: 6%;
  display: block;
  font-weight: 500;
  position: relative;
}

.reset-box-warp {
  margin: 40px auto;
  padding: 0 20px;
}

.reset-box-warp .columns:first-child {
  margin-left: 0;
}

.text-error {
  color: #eb3f3d;
  font-weight: 400;
  font-size: 12px;
}

.user-register-from {
  margin-top: 40px;
}
